import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'

class NotFoundRoute extends React.Component {
  render() {
    const { title, titleAction } = this.props.data.site.siteMetadata
    return (
      <Layout>
        <Helmet><title>404 NOT FOUND | {title}</title></Helmet>
        <div>
          <Sidebar {...this.props} />
          <div className="content">
            <div className="content__inner">
              <div className="page">
                <h1 className="page__title">NOT FOUND</h1>
                <div className="page__body">
                  <p>
                    There's nothing here other than a few lines of text. You
                    glance around the void in search of anything else, but there's nothing
                    more to be seen. What does it all mean?
                  </p>
                  <p>
                    It's pretty obvious isn't it? There's nothing here other
                    than what you're seeing. The thing you actually seek can be found
                    elsewhere.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NotFoundRoute

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
        titleAction
        subtitle
        menu {
          label
          path
        }
        author {
          name
          email
          instagram
          github
          rss
        }
      }
    }
  }
`
